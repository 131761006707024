<template>
	<div class="home">
		<div class="content-box hidden-sm-and-down" v-if="webMode === 1">
            <div class="page-products-bg">
                <video autoplay controls loop :height="videoHeight" muted v-if="adLink.pc">
                    <source :src="adLink.pc" type="video/mp4">
                </video>
            </div>
            <div class="intro-box page-products-select">
                <el-row class="page-content-box">
                    <el-col :span="11">
                        <div class="intro-box-left">
                            <div class="intro-box-title">{{ productSelectAreaData.title }}</div>
                            <div class="intro-box-content html-data-box" v-html="productSelectAreaData.description"></div>
                            <el-row class="intro-box-icon-dom">
                                <el-col :span="6" class="product-select-icon">
                                    <img src="/imgStatic/productsSelectIcon1.png" alt="">
                                    <div class="product-select-icon-title">{{ $t("productsData.productSelectData.introIconTitle1") }}</div>
                                </el-col>
                                <el-col :span="6" class="product-select-icon">
                                    <img src="/imgStatic/productsSelectIcon2.png" alt="">
                                    <div class="product-select-icon-title">{{ $t("productsData.productSelectData.introIconTitle2") }}</div>
                                </el-col>
                                <el-col :span="6" class="product-select-icon">
                                    <img src="/imgStatic/productsSelectIcon3.png" alt="">
                                    <div class="product-select-icon-title">{{ $t("productsData.productSelectData.introIconTitle3") }}</div>
                                </el-col>
                                <el-col :span="6" class="product-select-icon">
                                    <img src="/imgStatic/productsSelectIcon4.png" alt="">
                                    <div class="product-select-icon-title">{{ $t("productsData.productSelectData.introIconTitle4") }}</div>
                                </el-col>
                            </el-row>
                            <div class="btn intro-box-more" @click="handleOpenIframe">{{ productSelectAreaData.buttonText }}</div>
                        </div>
                    </el-col>
                    <el-col :span="13" class="intro-box-right-dom">
                        <div class="intro-box-right">
							<img :src="item.img" v-for="(item, index) in (productSelectAreaData.pictures || [])" :key="index" alt="">
                        </div>
                    </el-col>
                </el-row>
			</div>
            <div class="intro-box product-box">
				<div class="page-content-box">
					<div class="intro-box-title">{{ productBoxAreaData.title }}</div>
					<div class="intro-box-content html-data-box" v-html="productBoxAreaData.description"></div>
					<div class="intro-box-list">
						<el-row :gutter="48">
							<el-col :span="8" v-for="item in productList" :key="item.id">
								<div class="intro-box-list-node" @click="handleShowProductInfo(item)">
									<div class="intro-box-list-node-img">
										<img :src="item.cover.pc" alt="">
									</div>
									<div class="intro-box-list-node-content">
										<!-- <div class="intro-box-list-node-content-category" v-text="item.category"></div> -->
										<img src="/imgStatic/product-title-bg.png" alt="">
										<div class="intro-box-list-node-content-name" v-text="getLangContent(item.title)"></div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
            <div class="intro-box product-name-rule">
				<div class="page-content-box">
					<div class="intro-box-title">{{ productNameRuleAreaData.title }}</div>
					<img :src="item.img" v-for="(item, index) in (productNameRuleAreaData.pictures || [])" :key="index" alt="" class="product-name-rule-img">
				</div>
			</div>
            <div class="intro-box product-doc">
				<div class="page-content-box">
					<div class="intro-box-title">{{ productDocAreaData.title }}</div>
                    <div class="intro-box-list">
						<el-row :gutter="30" class="intro-box-list-row">
							<el-col :span="6" v-for="item in productDocList" :key="item.id">
								<div class="intro-box-list-node">
									<div class="intro-box-list-node-img">
										<img :src="item.cover" alt="">
									</div>
									<div class="intro-box-list-node-content">
										<div class="intro-box-list-node-content-title" v-text="getLangContent(item.name)"></div>
										<a :href="item.url" target="_blank" :download="getFileName(item.url)" class="btn btn-download">{{ $t("pageDom.downloadBtn") }}</a>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
		<div class="mobile-content-box hidden-md-and-up" v-if="webMode === 2">
            <div class="page-products-bg">
                <video autoplay loop controls :height="mobileVideoHeight" muted v-if="adLink.h5">
                    <source :src="adLink.h5" type="video/mp4">
                </video>
            </div>
            <div class="intro-box page-products-select">
                <el-row class="page-content-box">
                    <el-col :span="24">
                        <div class="intro-box-left">
                            <div class="intro-box-title">{{ productSelectAreaData.title }}</div>
                            <div class="intro-box-content html-data-box" v-html="productSelectAreaData.description"></div>
							<img :src="productSpecImg" alt="" class="intro-box-icon-img">
                            <div class="btn intro-box-more" @click="handleOpenIframe">{{ productSelectAreaData.buttonText }}</div>
                        </div>
                    </el-col>
                    <el-col :span="24" class="intro-box-right-dom">
                        <div class="intro-box-right">
							<img :src="item.img" v-for="(item, index) in (productSelectAreaData.pictures || [])" :key="index" alt="">
                        </div>
                    </el-col>
                </el-row>
			</div>
            <div class="intro-box product-box">
				<div class="page-content-box">
					<div class="intro-box-title">{{ productBoxAreaData.title }}</div>
					<div class="intro-box-content html-data-box" v-html="productBoxAreaData.description"></div>
					<div class="intro-box-list">
						<el-row :gutter="16">
							<el-col :span="12" v-for="item in productList" :key="item.id">
								<div class="intro-box-list-node" @click="handleShowProductInfo(item)">
									<div class="intro-box-list-node-img">
										<img :src="item.cover.h5" alt="">
									</div>
									<div class="intro-box-list-node-content">
										<!-- <div class="intro-box-list-node-content-category" v-text="item.category"></div> -->
										<img src="/imgStatic/product-title-bg.png" alt="">
										<div class="intro-box-list-node-content-name" v-text="getLangContent(item.title)"></div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
            <div class="intro-box product-name-rule">
				<div class="page-content-box">
					<div class="intro-box-title">{{ productNameRuleAreaData.title }}</div>
					<img :src="item.img" v-for="(item, index) in (productNameRuleAreaData.pictures || [])" :key="index" alt="" class="product-name-rule-img">
				</div>
			</div>
            <div class="intro-box product-doc">
				<div class="page-content-box">
					<div class="intro-box-title">{{ productDocAreaData.title }}</div>
                    <div class="intro-box-list">
						<el-row>
							<el-col :span="24" v-for="item in productDocList" :key="item.id">
								<div class="intro-box-list-node">
									<div class="intro-box-list-node-content" @click="handleShowProductDocDetail(item)">
										<div class="intro-box-list-node-content-title" v-text="getLangContent(item.name)"></div>
                                        <img class="intro-box-list-node-content-arrow" src="/imgStatic/rightArrow.png" alt="">
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
            <ProductDocDetail
                :showDialog="showMobileProductDocDetail"
				:productDocData="productDocDetailData"
				@colseDialog="handleCloseProductDocDetailDialog"
            />
		</div>
        <ProductSelect @close="handleCloseIframe" :defaultIframeType="defaultIframeType" :iframePath="iframePath" />
        <CodeProduct
			:showCodeProductDialog="showCodeProductDialog"
			:productData="codeProductDialogData"
			@closeProductDialog="handleCloseProductDialog"
		/>
		<iframe style="display: none;" name="downloadFile"></iframe>
	</div>
</template>

<script>
import ProductSelect from '@/components/ProductSelect.vue';
import CodeProduct from '@/components/CodeProduct.vue';
import ProductDocDetail from '@/components/ProductDocDetail.vue';

import {
	getAd,
	getSeries,
	getDownloadList,
	getPageFragment,
} from '@/api/common';

const productSpecZhImg = '/imgStatic/productSpec_zh.png';
const productSpecEnImg = '/imgStatic/productSpec_en.png';
const productSpecJaImg = '/imgStatic/productSpec_ja.png';

export default {
	name: 'Products',
	components: {
        ProductSelect,
        CodeProduct,
        ProductDocDetail,
	},
	data() {
		return {
			pageUrl: '/products',
			cId: 7,
			adLink: {},
			productSelectAreaData: {},
			productBoxAreaData: {},
			productNameRuleAreaData: {},
			productDocAreaData: {},
            productList: [],
            productDocList: [],
            defaultIframeType: 0,
            iframePath: '',
            publicPath: process.env.BASE_URL,
            showCodeProductDialog: false,
			codeProductDialogData: {},
            showMobileProductDocDetail: false,
			productDocDetailData: {},
			videoHeight: 0,
			mobileVideoHeight: 0,
		};
	},
	created() {
		this.init();
	},
	beforeMount() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
    mounted() {
		this.handleResize();
	},
	methods: {
		init() {
			this.getSeriesFunc();
			this.getDownloadListFunc();
		},
		getAdFunc() {
			getAd({
				id: this.adId,
			}).then(res => {
				this.adLink = (((res || {}).items || [])[0] || {}).url;
			});
		},
		getPageFragmentFunc() {
			getPageFragment({
				cid: this.cId,
				lang: this.keyConfig[this.lang],
			}).then(res => {
				(res || []).forEach(item => {
					switch (item.code) {
						// 产品选型板块内容
						case 'key-7-1':
							this.productSelectAreaData = item;
							this.iframePath = item.buttonLink;
							break;
						// 核心产品应用领域板块内容
						case 'key-7-2':
							this.productBoxAreaData = item;
							break;
						// 产品规格型号命名规则板块内容
						case 'key-7-3':
							this.productNameRuleAreaData = item;
							break;
						// 产品技术文档板块内容
						case 'key-7-4':
							this.productDocAreaData = item;
							break;
						default:
							break;
					}
				});
			});
		},
		getSeriesFunc() {
			getSeries().then(res => {
				this.productList = res || [];
			});
		},
		getDownloadListFunc() {
			getDownloadList().then(res => {
				this.productDocList = res || [];
			});
		},
		handleResize() {
			const zoom = document.body.style.zoom || 1;
			this.videoHeight = `${((document.documentElement.scrollWidth * 368) / 1920) / zoom}px`;
			this.mobileVideoHeight = `${(document.documentElement.clientWidth * 368) / 640}px`;
		},
		handleToPage(item) {
			window.open(`/newsDetail?id=${item.id}`);
		},
        handleOpenIframe() {
            this.defaultIframeType = 1;
        },
        handleCloseIframe() {
            this.defaultIframeType = 0;
        },
        handleShowProductInfo(item) {
			this.showCodeProductDialog = true;
			this.codeProductDialogData = item;
		},
        handleCloseProductDialog() {
			this.showCodeProductDialog = false;
			this.codeProductDialogData = {};
		},
        handleShowProductDocDetail(item) {
			this.showMobileProductDocDetail = true;
			this.productDocDetailData = item;
		},
        handleCloseProductDocDetailDialog() {
			this.showMobileProductDocDetail = false;
			this.productDocDetailData = {};
		},
	},
	computed: {
        webMode() {
            return this.$store.state.webMode;
        },
		lang() {
			return this.$store.state.lang;
		},
		keyConfig() {
			return this.$store.state.keyConfig;
		},
		productSpecImg() {
			let productSpecImgTmp = productSpecZhImg;
			switch (this.lang) {
				case 'zh':
					productSpecImgTmp = productSpecZhImg;
					break;
				case 'en':
					productSpecImgTmp = productSpecEnImg;
					break;
				case 'ja':
					productSpecImgTmp = productSpecJaImg;
					break;
				default:
					break;
			}

			return productSpecImgTmp;
		},
		getFileName() {
			return item => {
				const itemArr = item.split('/');

				return decodeURIComponent(itemArr[itemArr.length - 1]);
			};
		},
		menuConfig() {
			return this.$store.state.menuConfig;
		},
        adId() {
			const { adId } = (this.menuConfig || []).find(item => item.position === 'main' && item.url === this.pageUrl) || {};

			return adId || '';
		},
    },
	watch: {
		adId: {
			immediate: true,
			handler(val) {
				if (val) {
					this.getAdFunc();
					this.getPageFragmentFunc();
				}
			},
		},
    },
};
</script>

<style scoped lang='scss'>
	.home {
		width: 100%;
		.content-box {
            .page-products-bg {
                display: block;
                width: 100%;
                background: #333333;
                video {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                }
            }
            .intro-box {
				.intro-box-title {
					font-size: 40px;
					font-weight: bold;
					color: #333333;
					line-height: 40px;
				}
				.intro-box-content {
					font-size: 18px;
					color: #333333;
				}
				.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #C3002F;
					cursor: pointer;
					user-select: none;
				}
				.btn:hover {
					background: #a60028;
				}
				.intro-box-more {
					width: 210px;
					height: 68px;
					font-size: 24px;
					font-weight: 500;
					color: #FFFFFF;
				}
                .btn-download {
					width: 210px;
					height: 68px;
					font-size: 24px;
					font-weight: 500;
					color: #FFFFFF;
					text-decoration: none;
				}
			}
            .page-products-select {
                padding-bottom: 158px;
				.page-content-box {
					display: flex;
					.intro-box-left {
						padding-top: 70px;
					}
                    .intro-box-right-dom {
                        display: flex;
                        .intro-box-right {
                            margin-left: auto;
                            padding-top: 159px;
                        }
                    }
					.intro-box-content {
						margin-top: 43px;
						line-height: 30px;
					}
                    .intro-box-icon-dom {
						margin: 43px 0 49px;
                        .product-select-icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
							img {
								height: 60px;
							}
                            .product-select-icon-title {
                                margin-top: 17px;
                                font-size: 22px;
                                font-weight: 500;
                                color: #333333;
                                line-height: 22px;
                            }
                        }
                    }
				}
            }
            .product-box {
				padding-bottom: 105px;
				.page-content-box {
					.intro-box-content {
						margin: 55px 0 70px;
                        font-size: 18px;
						line-height: 30px;
                        font {
                            display: block;
                        }
					}
					.intro-box-list {
						.intro-box-list-node {
							margin-bottom: 55px;
							position: relative;
							cursor: pointer;
							.intro-box-list-node-img {
								overflow: hidden;
								img {
									max-width: 100%;
									display: block;
									overflow: hidden;
									background-repeat: no-repeat;
									background-size: cover;
									background-position: 50%;
									background-color: #ccc;
									-webkit-transition: transform .3s ease-in-out;
									-webkit-transition: -webkit-transform .3s ease-in-out;
									transition: -webkit-transform .3s ease-in-out;
									transition: transform .3s ease-in-out;
									transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
									-webkit-transform-origin: center;
									transform-origin: center;
								}
								img:hover {
									-webkit-transform: scale(1.1);
									transform: scale(1.1);
								}
							}
							.intro-box-list-node-content {
								display: flex;
								flex-direction: column;
								align-items: center;
								// justify-content: center;
								width: 100%;
								// height: 80px;
								// background: rgba(255, 255, 255, .9);
								position: absolute;
								bottom: 0;
								// left: calc(50% - 164px);
								img {
									width: 100%;
								}
								.intro-box-list-node-content-category {
									margin-bottom: 12px;
									font-size: 16px;
									font-weight: 500;
									color: #C3002F;
									line-height: 16px;
								}
								.intro-box-list-node-content-name {
									position: absolute;
									bottom: 27px;
									font-size: 24px;
									font-weight: 500;
									color: #fff;
									line-height: 24px;
									word-wrap: break-word;
									word-break: break-all;
								}
							}
						}
					}
					.intro-box-more {
						margin: 0 auto;
					}
				}
			}
            .product-name-rule {
                padding-bottom: 118px;
                .intro-box-title {
                    margin-bottom: 76px;
                }
                .page-content-box {
                    .product-name-rule-img {
                        margin: 0 auto;
                        margin-bottom: 58px;
                    }
                }
            }
            .product-doc {
                padding: 80px 0 106px;
                background: #F0F0F0;
                .intro-box-title {
                    margin-bottom: 76px;
                }
                .page-content-box {
                    .intro-box-list {
						.intro-box-list-row {
							display: flex;
							flex-wrap: wrap;
						}
						.intro-box-list-node {
							margin-bottom: 120px;
							position: relative;
							cursor: pointer;
							.intro-box-list-node-img {
								overflow: hidden;
                                border: 1px solid #EEEEEE;
								img {
									display: block;
									overflow: hidden;
									max-width: 100%;
									background-repeat: no-repeat;
									background-size: cover;
									background-position: 50%;
									background-color: #ccc;
									-webkit-transition: transform .3s ease-in-out;
									-webkit-transition: -webkit-transform .3s ease-in-out;
									transition: -webkit-transform .3s ease-in-out;
									transition: transform .3s ease-in-out;
									transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
									-webkit-transform-origin: center;
									transform-origin: center;
								}
								img:hover {
									-webkit-transform: scale(1.1);
									transform: scale(1.1);
								}
							}
							.intro-box-list-node-content {
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								.intro-box-list-node-content-title {
                                    margin: 35px 0 45px;
                                    font-size: 18px;
                                    color: #333333;
                                    line-height: 24px;
								}
							}
						}
					}
                }
            }
        }
		.mobile-content-box {
            .page-products-bg {
                display: block;
                width: 100%;
                background: #333333;
                video {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                }
            }
            .intro-box {
				.intro-box-title {
					font-size: 28px;
					font-weight: bold;
					color: #333333;
					line-height: 28px;
				}
				.intro-box-content {
					font-size: 16px;
					color: #333333;
				}
				.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #C3002F;
					cursor: pointer;
					user-select: none;
				}
				.intro-box-more {
					height: 57px;
					font-size: 17px;
					font-weight: 500;
					color: #FFFFFF;
				}
			}
            .page-products-select {
				.page-content-box {
					.intro-box-left {
						padding-top: 40px;
					}
					.intro-box-right {
						margin-left: auto;
						padding-top: 40px;
						img {
							width: 100%;
						}
					}
					.intro-box-content {
						line-height: 26px;
						margin: 35px 0;
					}
                    .intro-box-icon-dom {
						margin: 36px 0;
                        .product-select-icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            img {
                                height: 45px;
                            }
                            .product-select-icon-title {
                                margin-top: 12px;
                                font-size: 16px;
                                font-weight: 500;
                                color: #333333;
                                line-height: 12px;
                            }
                        }
                    }
					.intro-box-icon-img {
						margin-bottom: 36px;
						max-width: 100%;
					}
				}
			}
            .product-box {
				padding: 75px 0;
				.page-content-box {
					.intro-box-content {
						margin: 40px 0;
						line-height: 27px;
                        font {
                            display: block;
                        }
					}
					.intro-box-list {
						.intro-box-list-node {
							margin-bottom: 35px;
							position: relative;
							.intro-box-list-node-img {
								overflow: hidden;
								img {
									display: block;
									width: 100%;
								}
							}
							.intro-box-list-node-content {
								display: flex;
								flex-direction: column;
								align-items: center;
								// justify-content: center;
								width: 100%;
								// height: 40px;
								// background: rgba(255, 255, 255, .9);
								position: absolute;
								bottom: 0;
								// left: 6.25%;
								img {
									width: 100%;
								}
								.intro-box-list-node-content-category {
									margin-bottom: 9px;
									font-size: 13px;
									font-weight: 500;
									color: #C3002F;
									line-height: 13px;
								}
								.intro-box-list-node-content-name {
									position: absolute;
									bottom: 27px;
									text-align: center;
									font-size: 14px;
									font-weight: 500;
									color: #fff;
									line-height: 14px;
									word-wrap: break-word;
									word-break: break-all;
								}
							}
						}
					}
				}
			}
            .product-name-rule {
                padding-bottom: 90px;
                .intro-box-title {
                    margin-bottom: 35px;
                }
                .page-content-box {
                    .product-name-rule-img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                }
            }
            .product-doc {
                padding: 43px 0 75px;
                background: #F0F0F0;
                .intro-box-title {
                    margin-bottom: 43px;
                }
                .page-content-box {
                    .intro-box-list {
                        border-top: 1px solid #DFDCDC;
						.intro-box-list-node {
							display: flex;
                            align-items: center;
                            height: 75px;
                            border-bottom: 1px solid #DFDCDC;
							.intro-box-list-node-content {
								display: flex;
								align-items: center;
                                width: 100%;
								.intro-box-list-node-content-title {
                                    padding-right: 30px;
                                    font-size: 17px;
                                    color: #333333;
                                    line-height: 21px;
								}
                                .intro-box-list-node-content-arrow {
                                    margin-left: auto;
                                    width: 12px;
                                }
							}
						}
					}
                }
            }
        }
	}
</style>
<style scoped lang="scss">
  .app-en {
    .app-pc {
		.intro-box-content {
			line-height: 24px !important;
		}
		.product-select-icon-title {
			font-size: 14px !important;
			line-height: 18px !important;
			text-align: center;
		}
		.product-doc {
			.intro-box-list-node-content-title {
				min-height: 48px;
			}
		}
    }
	.app-mobile {
		.product-box {
			.intro-box-content {
				font-size: 16px !important;
				line-height: 21px !important;
			}
			.intro-box-list-node-content-category {
				font-size: 12px !important;
				line-height: 12px !important;
				margin-bottom: 7px !important;
			}
			.intro-box-list-node-content-name {
				font-size: 13px !important;
				line-height: 13px !important;
			}
		}
		.product-select-icon-title {
			font-size: 14px !important;
			line-height: 14px !important;
			text-align: center;
		}
	}
  }
  .app-ja {
    .app-pc {
		.product-box {
			.intro-box-list-node-content-category {
				margin-bottom: 10px !important;
			}
			.intro-box-list-node-content-name {
				padding: 0 30px !important;
				font-size: 20px !important;
				line-height: 22px !important;
			}
		}
		.product-select-icon-title {
			font-size: 13px !important;
			line-height: 16px !important;
			text-align: center;
		}
	}
	.app-mobile {
		.product-box {
			.intro-box-content {
				line-height: 23px;
			}
			.intro-box-list-node-content-category {
				margin-bottom: 5px !important;
			}
			.intro-box-list-node-content-name {
				font-size: 10px !important;
				padding: 0 5px !important;
			}
		}
		.product-select-icon-title {
			padding: 0 5px;
			font-size: 14px !important;
			line-height: 14px !important;
			text-align: center;
		}
	}
  }
</style>
