<template>
  <div>
    <el-dialog
      :visible.sync="showDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :fullscreen="true"
      custom-class="productdoc-detail-mobile-dialog"
      @close="handleCloseDialog"
    >
      <div class="productdoc-detail-info">
        <div class="productdoc-detail-dialog-title">
          <img class="productdoc-detail-dialog-close" src="/imgStatic/closeIcon.png" alt="" @click="handleCloseDialog" />
        </div>
        <div class="productdoc-detail-dialog-content">
          <img :src="productDocData.cover" alt="" class="productdoc-detail-img">
          <div class="productdoc-detail-title">{{ getLangContent(productDocData.name) }}</div>
          <a :href="productDocData.url" target="_blank" :download="getFileName(productDocData.url)" class="btn intro-box-more">{{ $t("pageDom.downloadBtn") }}</a>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    name: 'ProductDocDetail',
    props: {
        showDialog: Boolean,
        productDocData: Object,
    },
    components: {
    },
    data() {
      return {};
    },
    created() {},
    methods: {
      handleCloseDialog() {
        this.$emit('colseDialog');
      },
    },
    computed: {
      webMode() {
        return this.$store.state.webMode;
      },
      getFileName() {
        return item => {
          const itemArr = (item || '').split('/');

          return decodeURIComponent(itemArr[itemArr.length - 1]);
        };
      },
    },
    watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /deep/.productdoc-detail-mobile-dialog {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 30px;
      .productdoc-detail-info {
        .productdoc-detail-dialog-title {
          display: flex;
          align-items: center;
          .productdoc-detail-dialog-close {
            margin-left: auto;
            width: 21px;
            cursor: pointer;
          }
        }
        .productdoc-detail-dialog-content {
          padding-top: 40px;
          .productdoc-detail-img {
            max-width: 100%;
            border: 1px solid #EEEEEE;
          }
          .productdoc-detail-title {
            margin: 20px 0;
            text-align: center;
            font-size: 16px;
            color: #333333;
            line-height: 16px;
          }
          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #C3002F;
            user-select: none;
          }
          .intro-box-more {
            height: 57px;
            font-size: 17px;
            font-weight: 500;
            color: #FFFFFF;
            text-decoration: none;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  .app-mobile {
  }
</style>
